import React from 'react'

import { FormBuilder, HiddenBuilder, PhoneBuilder } from 'meta/form'
import { ApiEndpoint } from 'controller/endpoint'
import { CookieClient, CookieName } from 'utils/cookie'

import Form, { IFormOnSuccess } from 'components/Forms/Form'
import { useCheckoutDetails } from 'hooks/useCheckout'
import { useEnableRecaptcha } from 'hooks/useEnv'

interface IPropsPhoneNumber {
  action: string
  onSuccess: IFormOnSuccess
  phone: string
  prefix: string
}

const PhoneForm: React.FC<IPropsPhoneNumber> = (props) => {
  const { action, onSuccess, phone, prefix } = props

  const details = useCheckoutDetails()

  const enableRecaptcha = useEnableRecaptcha()

  const recaptchaAction = enableRecaptcha ? { action: 'shop/auth/phone' } : null

  const phoneField = new PhoneBuilder('phone', 'phonePrefix')
    .addValuePrefix(prefix)
    .addValue(phone)
    .addRequiredValidation()
    .build()

  const csrfToken = CookieClient.get(CookieName.CSRF_TOKEN)

  const csrfField = new HiddenBuilder('_csrfToken').addValue(csrfToken).build()

  const backendRouteField = new HiddenBuilder('backend_route').addValue(ApiEndpoint.users.otp()).build()

  const insideIframe = new HiddenBuilder('_insideIframe')
    .addValue(Boolean(details && details.insideIFrame).toString())
    .build()

  const formFields = new FormBuilder()
    .addField(phoneField)
    .addField(csrfField)
    .addField(insideIframe)
    .addField(backendRouteField)
    .build()

  return (
    <Form
      action={action}
      formFields={formFields}
      labelSubmit="registerV2:next_step_label"
      onSuccess={onSuccess}
      //@ts-ignore
      recaptcha={recaptchaAction}
    />
  )
}

export default PhoneForm
