import css from './Copyright.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'

// import { ProcessEnv } from 'utils/env'
import { GridColumn } from 'components/Layout/Grid'

interface Props {}

const Copyright: React.FC<Props> = () => {
  const { t } = useTranslation(['footer'])

  return (
    <GridColumn gridColumnEnd={17} className={css.footerCopyright}>
      <div className={css.row}>
        &copy; <b>TicketSms</b>_{t('footer:all_rights_reserved')}
      </div>
      <div className={css.row}>
        {t('footer:system-version')}
        {` 3.1`}
      </div>
      {/* {` | v`}
      {ProcessEnv.version} */}
    </GridColumn>
  )
}

export default Copyright
