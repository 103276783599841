import { useEffect } from 'react'

import { useShowOnlyContent } from 'components/Layout/hooks/useShowOnlyContent'
import { useGoogleAnalyticsId } from 'hooks/useEnv'

export const useInitReactGa4 = () => {
  const googleAnalytics4Id = useGoogleAnalyticsId()
  const { onlyContent } = useShowOnlyContent()

  useEffect(() => {
    !onlyContent &&
      import('react-ga4')
        .then((module) => module.default)
        .then((ReactGA) => {
          if (googleAnalytics4Id) {
            ReactGA.initialize(googleAnalytics4Id)
            ReactGA.send('pageview')
          }
        })
  }, [googleAnalytics4Id])
}
