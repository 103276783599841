import { IAsset } from 'meta/backendControlled/structure'

interface IWalletUserTicketSector {
  name: string
}

export interface IWalletEvent {
  codeUrl: string
  dateEvent: string
  dateEvent_tz: string
  eventAssets: Array<IAsset>
  id: number
  idLocation: number
  isEventCollection?: boolean
  name: string
}

export interface IWalletUserTicket {
  buyerName: string
  dateEntry?: string
  dateEntry_tz?: string
  dateEntry_utc?: string
  fiscalSeal: string
  id: number
  isSeasonTicket?: boolean
  notes: string
  seatId?: string
  secret: string
  seasonName?: string
  sectorName?: string
  sector: IWalletUserTicketSector
  status: string
  statusLabel: string
  ticketDescription: string
  ticketMoreData?: Array<{ field: string; value: string }>
  ticketStatus: string
  validityDate: string
  validityDate_tz: string
  validityDate_utc: string
}

export interface IWalletUserTicketPrice {
  amount: string
  currency: string
  symbol: string
  formatted: string
}

export interface IWalletUserTicketDetailsEvent {
  codeUrl: string
  name: string
  datetime_tz: string
}

export interface IWalletUserTicketDetailsEventTicket {
  id: number
  event: IWalletUserTicketDetailsEvent
  status: string
  entryDateTime: string
}

export interface IWalletUserTicketDetails {
  activationCard: string
  buyerName: string
  dateEntry?: string
  dateEvent: string
  eventTickets?: IWalletUserTicketDetailsEventTicket[]
  emissionDate: Date
  emissionSystem: string
  eventName: string
  fiscalProgressive: number
  fiscalSeal: string
  id: number
  isSeasonTicket?: boolean
  locationName: string
  openDateEvent: string
  orderCodeDescription: string
  organizerCompanyName: string
  organizerVat: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  personalData?: any
  presale: IWalletUserTicketPrice
  price: IWalletUserTicketPrice
  secret: string
  status: string
  statusLabel: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ticketMoreData?: any
  totalPrice: IWalletUserTicketPrice
  typeTicketDescription: string
}

export interface IWalletUserTicketPersonalData {
  gender: string
  birthDate: string
  birthplaceCountry: string
  birthplaceCity?: string
}

export interface IWalletLocation {
  city: string
  common: string
  id: 1001
  locationAssets: Array<IAsset>
  name: string
}

export interface IWalletTicket {
  event: IWalletEvent
  location: IWalletLocation
  userTicketsNumber: number
  // userTickets: Array<IWalletUserTicket>
}

export interface IWalletSeasonTicket {
  id: number
  seasonName: string
  typeTicketDescription: string
  seasonCode: string
  numberOfEntrances: number
  endDate: string
  endDate_tz: string
  endDate_utc: string
}

export enum IWalletTicketFilter {
  old = 'old',
  new = 'new',
}
