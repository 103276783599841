import css from './Base.module.scss'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import QRCode from 'qrcode.react'

import { MapIDs } from 'meta/map'
import { IWalletSeasonTicket, IWalletUserTicket } from 'meta/pages/wallet'

import Img from 'components/Media/Img'
import { useTicketStatus } from 'components/Pages/Wallet/hooks/useTicketStatus'
import { useUrlCloudfront } from 'hooks/useEnv'

type Props = {
  show: boolean
  userTicket: IWalletUserTicket & IWalletSeasonTicket
}

const TicketCardBase: React.FC<Props> = (props) => {
  const { show, userTicket } = props

  const { t } = useTranslation()

  const urlCloudfront = useUrlCloudfront()

  const statusSplit = useMemo(
    () => userTicket.statusLabel && userTicket.statusLabel.split('-'),
    [userTicket.statusLabel]
  )
  const statusHeader = useMemo(() => statusSplit && statusSplit[0], [statusSplit])

  const status = useTicketStatus({ userTicket })

  const seatIDs = userTicket.seatId ? MapIDs.toSeatIDs(userTicket.seatId) : null

  return (
    <>
      <div className={css.walletTicketCard__base}>
        <div className={classNames(css.walletTicketCard__qrCode, { [css.walletTicketCard__hidden]: !show })}>
          {userTicket.secret ? (
            <QRCode value={userTicket.secret} size={173} />
          ) : (
            <Img src={`/wallet/locked_qr_code.jpg`} />
          )}
        </div>

        <div className={css.walletTicketCard__info}>
          <h2>{userTicket.buyerName ? userTicket.buyerName : '-'}</h2>
          <div className={css.sep} />
          <h5>
            {userTicket.typeTicketDescription && <span>{userTicket.typeTicketDescription}</span>}
            {userTicket.typeTicketDescription &&
              userTicket.seasonName &&
              !userTicket?.typeTicketDescription?.includes('-') &&
              ` - `}
            {userTicket.seasonName && <span>{`${userTicket.seasonName}`}</span>}
            {userTicket.notes && !userTicket?.typeTicketDescription?.includes(userTicket.notes) && (
              <span>{`${userTicket.notes}`}</span>
            )}
          </h5>
          {userTicket?.sector?.name && <h5>{t('wallet:sector', { sectorName: userTicket?.sector?.name })}</h5>}
          {seatIDs && <h5>{t('wallet:row', { row: seatIDs.row })}</h5>}
          {seatIDs && <h5>{t('wallet:seat', { seat: seatIDs.seat })}</h5>}
          {seatIDs && <h5>{t('wallet:section', { section: seatIDs.section })}</h5>}
          <div>{userTicket.ticketDescription}</div>
          {userTicket.ticketMoreData &&
            userTicket.ticketMoreData.map((more) => (
              <React.Fragment key={more.field}>
                <div className={css.sep} />
                <div style={{ display: 'flex' }}>
                  <h5>{more.field}:&nbsp;</h5>
                  <div>{more.value}</div>
                </div>
              </React.Fragment>
            ))}
        </div>
        {userTicket.isSeasonTicket && <div>{t('wallet:isSeasonTicket')}</div>}
      </div>
      <div
        className={classNames(css.walletTicketCard__status, css[userTicket.status], {
          [css.walletTicketCard__hidden]: !show,
        })}
        style={{
          backgroundImage: `url(${urlCloudfront}/images/wallet/status_${userTicket.status}.svg)`,
        }}
      >
        <h3>{statusHeader}</h3>
        <div>{status}</div>
      </div>
    </>
  )
}

export default TicketCardBase
