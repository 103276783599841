import css from './Form.module.scss'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BeatLoader } from 'react-spinners'
import classNames from 'classnames'

import { FormBuilder, HiddenBuilder, TextBuilder } from 'meta/form'
import { TICKETSMS_COLORS_RED } from 'utils/constants/colors'
import { CookieClient, CookieName } from 'utils/cookie'
import { getValidDigitRegex } from 'utils/regex'

import { ActionColor, Button } from 'components/Actions/Action'
import Form, { cssForm, IFormOnSuccess, IFormStatus, IFormValues } from 'components/Forms/Form'
import { useEnableRecaptcha, useS2SToken } from 'hooks/useEnv'
import { useOnUpdate } from 'hooks/useOnUpdate'

import OTPDigits from './OTPDigits'
import { useResendOtp } from './useResendOtp'

interface IPropsOTPForm {
  action: string
  actionResend: string
  authToken: string
  onSuccess: IFormOnSuccess
  otpToken: string
  phone: number
  phonePrefix: string

  bodyParser?(otp: string, otpToken: string): object
}

const otpDigits = ['first', 'second', 'third', 'fourth']

const OTPForm: React.FC<IPropsOTPForm> = (props) => {
  const { action, actionResend, authToken, bodyParser, onSuccess, otpToken: otpTokenProps, phone, phonePrefix } = props

  const { t } = useTranslation()

  const S2SToken = useS2SToken()

  const {
    canResend,
    error: errorResend,
    resend,
    otpToken,
    timeLeft,
  } = useResendOtp(actionResend, authToken, otpTokenProps, phone, phonePrefix, S2SToken)

  //@ts-ignore
  const [values, setValues] = useState<Array<string>>([null, null, null, null])
  const [formStatus, setFormStatus] = useState<IFormStatus>({})
  const { fetching, error, response } = formStatus
  const errorForm = error || errorResend

  const enableRecaptcha = useEnableRecaptcha()

  const csrfToken = CookieClient.get(CookieName.CSRF_TOKEN)

  const csrfField = new HiddenBuilder('_csrfToken').addValue(csrfToken).build()

  const bodyParserOTP = (data: IFormValues) => {
    const otp = `${data.first}${data.second}${data.third}${data.fourth}`
    //@ts-ignore
    return { ...bodyParser(otp, otpToken) }
  }

  useOnUpdate(() => {
    // auto submit form after entering all digits
    const filled = values.every((value) => value !== null && value.trim() !== '')
    if (filled) {
      //@ts-ignore
      document.getElementById('submitOTP').click()
    }
  }, [values])

  const formBuilder = new FormBuilder()

  formBuilder.addField(csrfField)

  otpDigits.forEach((digit) => {
    formBuilder.addField(
      new TextBuilder(digit)
        .addValidation({
          max: 1,
          pattern: {
            value: getValidDigitRegex(),
            message: t('otpV2:errors_form_invalid_digit'),
          },
          required: 'otpV2:errors_form_required_digit',
        })
        .build()
    )
  })

  return (
    <Form
      authToken={authToken}
      action={action}
      bodyParser={bodyParserOTP}
      formFields={formBuilder.build()}
      id="otp-form"
      onSuccess={onSuccess}
      renderError={false}
      renderFields={false}
      //@ts-ignore
      recaptcha={enableRecaptcha ? { action: 'shop/auth/otp' } : null}
      setFormStatus={setFormStatus}
    >
      <div className={css.otpForm__fields}>
        <OTPDigits errorForm={errorForm} otpDigits={otpDigits} setValues={setValues} />
      </div>

      <div className={classNames(cssForm.form__buttonBar, css.otpForm__buttonBar)} datatype="form-buttonbar">
        <Button
          className={css.otpForm__button}
          disabled={!canResend}
          backgroundColor={ActionColor.transparent}
          borderColor={ActionColor.transparent}
          color={ActionColor.black}
          onClick={resend}
        >
          <div>{t('otpV2:resend_otp_label')}</div>
          {!canResend && (
            <>
              <div style={{ marginLeft: '4px' }}>&gt;</div>
              <div style={{ marginLeft: '8px' }}>{timeLeft}</div>
            </>
          )}
        </Button>
      </div>

      <div className={classNames(cssForm.form__buttonBar, css.otpForm__buttonBar)} datatype="form-buttonbar">
        {response || fetching ? (
          <BeatLoader color={TICKETSMS_COLORS_RED} />
        ) : (
          <button
            id="submitOTP"
            className={classNames(cssForm.form__buttonSubmit, css.otpForm__button)}
            data-testid="submit"
            disabled={fetching}
            type="submit"
          >
            {t('otpV2:submit_label')}
          </button>
        )}
      </div>
    </Form>
  )
}

OTPForm.defaultProps = {
  bodyParser: (otp: string, otpToken: string): object => ({ otp, otpToken }),
}

export default OTPForm
