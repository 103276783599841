import css from './SupportForm.module.scss'
import React, { MutableRefObject, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import axios from 'axios'
import classNames from 'classnames'

import DataTestId from 'meta/dataTestId'
import { FormBuilder, HiddenBuilder, PhoneBuilder, TextAreaBuilder, TextBuilder } from 'meta/form'
import { ISupportForm, ISupportFormResponse, ISupportFormTopic } from 'meta/pages/support'
import { ApiEndpoint } from 'controller/endpoint'
import { TICKETSMS_COLORS_LIGHTGREY, TICKETSMS_COLORS_WHITE } from 'utils/constants/colors'

import Support from 'components/common/Support'
import WithAppInitialized from 'components/common/WithAppInitialized'
import Form, { IFormContext } from 'components/Forms/Form'
import FormSubmissionConfirm from 'components/Forms/FormSubmissionConfirm'
import withTranslation, { useI18NContext } from 'components/HOC/WithTranslation'
import FAQ from 'components/Pages/SupportForm/FAQ'
import { SupportFormSkeleton } from 'components/Pages/SupportForm/SupportFormSkeleton'
import { useBackendApiBase, useEnableRecaptcha } from 'hooks/useEnv'

const SupportForm: React.FC = () => {
  const { t } = useTranslation()
  const [topicIdActive, setTopicIdActive] = useState<number>()
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [showForm, setShowForm] = useState<boolean>(false)
  const [supportForm, setSupportForm] = useState<ISupportForm>()

  const enableRecaptcha = useEnableRecaptcha()

  const backendApiBase = useBackendApiBase()

  const { namespacesLoaded } = useI18NContext()

  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formContextRef: MutableRefObject<IFormContext<any>> = useRef<IFormContext<any>>(null)

  const topicId = 'supportTopicId'
  const topic = new HiddenBuilder(topicId).addRequiredValidation().build()
  const firstName = new TextBuilder('firstName').addRequiredNameValidation().addPlaceholder('form:firstName').build()
  const lastName = new TextBuilder('lastName').addRequiredNameValidation().addPlaceholder('form:lastName').build()
  const phone = new PhoneBuilder('phone').addRequiredValidation().addPlaceholder('form:phone').build()
  const email = new TextBuilder('email').addRequiredEmailValidation().addPlaceholder('form:email').build()
  const message = new TextAreaBuilder('message')
    .addRows(10)
    .addRequiredValidation()
    .addPlaceholder(t('form:message'))
    .build()

  const formBuilder = new FormBuilder()
    .addField(topic)
    .addField(firstName)
    .addField(lastName)
    .addField(phone)
    .addField(email)
    .addField(message)

  const onSuccess = () => setShowConfirm(true)

  useEffect(() => {
    axios
      .get(`${backendApiBase}/support/form`)
      .then((response) => response.data)
      .then((response: ISupportFormResponse) => setSupportForm(response.data))
  }, [backendApiBase])

  if (showConfirm) return <FormSubmissionConfirm messageKey="support-form:submitSuccess" />

  return (
    <Support>
      <WithAppInitialized
        skeleton={
          <div className={classNames(css.centerLandscape__skeleton)}>
            <Skeleton
              baseColor={TICKETSMS_COLORS_LIGHTGREY}
              borderRadius={8}
              height={'100%'}
              highlightColor={TICKETSMS_COLORS_WHITE}
            />
          </div>
        }
        showChildren={namespacesLoaded}
      >
        <div className={css.centerLandscape}>{t('support-form:selectTopic')}</div>
      </WithAppInitialized>
      <WithAppInitialized skeleton={<SupportFormSkeleton />} showChildren={Boolean(supportForm)}>
        <div className={css.topics}>
          {supportForm?.topics.map((topic: ISupportFormTopic, key) => {
            const { id } = topic
            return (
              <button
                key={id}
                data-testid={key === 0 ? DataTestId.support.formTestButton : null}
                type="button"
                className={classNames(css.topic, { [css.topicActive]: id === topicIdActive })}
                onClick={() => {
                  setTopicIdActive(id)
                  // @ts-ignore
                  formContextRef.current?.setFieldValue(topicId, id)
                }}
              >
                <img alt={topic.title} src={topic.icon} />
                <div className={css.topicTitle}>{topic.title}</div>
              </button>
            )
          })}
          <div id={`${topicId}-error`} />
        </div>
      </WithAppInitialized>
      {topicIdActive && (
        <div className={css.faqContainer}>
          <FAQ setShowForm={setShowForm} topicId={topicIdActive} />
        </div>
      )}
      <div className={classNames(css.formContainer, { [css.hidden]: !showForm })}>
        <Form
          action={ApiEndpoint.support.form()}
          // @ts-ignore
          formContextRef={formContextRef}
          formFields={formBuilder.build()}
          onSuccess={onSuccess}
          recaptcha={enableRecaptcha ? { action: 'shop/support/form' } : undefined}
        />
      </div>
    </Support>
  )
}

const namespaces = ['support', 'support-form']

export default withTranslation(namespaces)(SupportForm)
