import React, { useLayoutEffect, useRef } from 'react'
import { MapType } from 'types/cartographer'

import { useAppEnv } from 'hooks/useEnv'

type Props = {
  codeUrl?: string
  headers: Record<string, string>
  eventId?: number
  typeTicketCollectionId?: number
  lang?: string
  mapId: string
  mapType: MapType
  mode: string
  platform: string
  preview: boolean
  promoterId?: number
  seatIds?: Array<string>
}

interface CartographerProps {
  [key: string]: unknown
}

const elementId = 'silent-cartographer-root'
const authTokenName = 'ts_JWT_PROMOTER_AUTH'
const provider = 'digitalVenue'

const Map: React.FC<Props> = (props) => {
  const {
    codeUrl,
    eventId,
    typeTicketCollectionId,
    headers,
    lang,
    mapId,
    mapType,
    mode,
    platform,
    preview,
    promoterId,
    seatIds,
  } = props

  const appEnv = useAppEnv()
  const initializedRef = useRef<boolean>(false)

  useLayoutEffect(() => {
    if (appEnv && !initializedRef.current) {
      const selector = `#${elementId}`
      const shop = { codeUrl, preview, promoterId, seatIds: seatIds?.map((seatId) => ({ seatId })) ?? undefined }
      const cartographerProps: CartographerProps = {
        selector,
        appEnv,
        authTokenName,
        headers,
        lang,
        mapId,
        mapType,
        mode,
        platform,
        provider,
        shop,
      }

      if (typeTicketCollectionId) {
        cartographerProps['typeTicketCollectionId'] = typeTicketCollectionId
      } else {
        cartographerProps['eventId'] = eventId
      }

      // @ts-expect-error window might not have property
      new window.SilentCartographer(cartographerProps)

      initializedRef.current = true
    }
  }, [appEnv, codeUrl, eventId, headers, lang, mapId, mapType, mode, platform, preview, promoterId, seatIds])

  return <div id={elementId} />
}

Map.defaultProps = {
  lang: 'it',
}

export default Map
