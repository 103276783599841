import css from './Desktop.module.scss'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import classNames from 'classnames'

import { ITicket } from 'meta/backendControlled/structure'
import { CurrencySymbol } from 'meta/currency'
import { RouteParams } from 'meta/routes'
import { convertAndFormat, getCodeFromSymbol } from 'helpers/currency/converter'
import { Dates } from 'utils/dates'
import { convertDotsToCommas } from 'utils/string'

import EventFeesButton from 'components/BackendControlled/Component/common/EventFeesButton'
import { Grid, GridColumn } from 'components/Layout/Grid'
import Img from 'components/Media/Img'
import { useActiveCurrency } from 'hooks/useActiveCurrency'

interface IProps {
  data: ITicket
  // eslint-disable-next-line @typescript-eslint/ban-types
  callback: Function
}

const Desktop: React.FC<IProps> = (props) => {
  const { callback, data } = props
  const { currency, id, title, orderCodeDescription, priceNumber, status, availableUntil, sector, details } = data

  const ticketTitle = sector && sector.name ? `${sector.name} - ${title}` : title

  const { t } = useTranslation()
  const { activeCurrency } = useActiveCurrency()
  const { codeUrl } = useParams<RouteParams['Event']>()

  const [isOpen, setOpen] = useState(false)

  const disabled = status === 'soldOut' || status === 'comingSoon'

  const toggleTicketOpen = () => {
    setOpen(!isOpen)
  }

  const execCallback = () => {
    if (status === 'soldOut' || status === 'comingSoon') {
      return
    }

    callback(id, orderCodeDescription)
  }

  const getAvailableUntil = () => {
    return Dates.format(new Date(availableUntil), t('dates:common:ticket'))
  }

  const getTitle = () => {
    if (ticketTitle.length > 40 && isOpen) {
      return `${ticketTitle.substr(0, 40)}...`
    }

    return ticketTitle
  }

  const eventCurrencyCode = getCodeFromSymbol(currency as CurrencySymbol)

  const convertedPrice = convertAndFormat(priceNumber, eventCurrencyCode, activeCurrency)

  return (
    <Grid className={css.eventTicketContainer}>
      <GridColumn gridColumnEnd={17}>
        <div
          className={classNames(css.event_body_tickets_ticket_desktop_container, {
            [css.disabled]: disabled,
          })}
          onClick={execCallback}
        >
          <div onMouseOver={toggleTicketOpen} onMouseOut={toggleTicketOpen} className={css.whitebox_container}>
            <div className={css.ticket_image}>
              <Img src="/tickets/ticket_logo_placeholder.svg" />
            </div>

            <div className={classNames(css.ticket_info, { [css.grey]: disabled })}>
              <h3 className={css.title}>{getTitle()}</h3>
              <div>
                <div className={css.details}>{details}</div>
                {availableUntil && <div>{`${t('event:purchase_available_until')} ${getAvailableUntil()}`}</div>}
              </div>
            </div>

            <div className={classNames(css.ticket_price_box, { [css.grey]: disabled })}>
              {
                <h2 className={classNames(css.price, { [css.hide]: status === 'comingSoon' })}>{`${convertDotsToCommas(
                  convertedPrice
                )}`}</h2>
              }
              {status !== 'comingSoon' && <EventFeesButton codeUrl={codeUrl as string} />}
            </div>
          </div>

          <div className={css.ticket_inner_container}>
            {status === 'active' && <Img src={'/tickets/ticket_tip_green.svg'} />}
            {status === 'exhaust' && <Img src={'/tickets/ticket_tip_yellow.svg'} />}
            {status === 'soldOut' && <Img src={'/tickets/ticket_tip_red.svg'} />}
            {status === 'comingSoon' && <Img src={'/tickets/ticket_tip_purple.svg'} />}

            <div className={css.text}>
              {status === 'active' && t('event:ticket_available')}
              {status === 'exhaust' && t('event:ticket_last_places_remaining')}
              {status === 'soldOut' && t('event:ticket_soldout')}
              {status === 'comingSoon' && t('event:ticket_tba')}
            </div>
          </div>
        </div>
      </GridColumn>
    </Grid>
  )
}

export default Desktop
