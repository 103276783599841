import css from './Mobile.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import classNames from 'classnames'

import { ITicket } from 'meta/backendControlled/structure'
import { CurrencySymbol } from 'meta/currency'
import { RouteParams } from 'meta/routes'
import { convertAndFormat, getCodeFromSymbol } from 'helpers/currency/converter'
import { convertDotsToCommas } from 'utils/string'

import EventFeesButton from 'components/BackendControlled/Component/common/EventFeesButton'
import { Grid, GridColumn } from 'components/Layout/Grid'
import Img from 'components/Media/Img'
import { useActiveCurrency } from 'hooks/useActiveCurrency'

const ticketInfoByStatus: Record<string, { label: string; img: string }> = {
  active: { label: 'event:ticket_available', img: '/tickets/ticket_tip_green.svg' },
  exhaust: { label: 'event:ticket_last_places_remaining', img: '/tickets/ticket_tip_yellow.svg' },
  soldOut: { label: 'event:ticket_soldout', img: '/tickets/ticket_tip_red.svg' },
  comingSoon: { label: 'event:ticket_tba', img: '/tickets/ticket_tip_purple.svg' },
}

interface IPropsTicketMobile {
  data: ITicket
  callback(id: number, orderCodeDescription: string): void
}

const Mobile: React.FC<IPropsTicketMobile> = (props) => {
  const { callback, data } = props
  const { id, title, currency, orderCodeDescription, priceNumber, status, sector, details } = data
  const eventDisabled = ['soldOut', 'comingSoon'].includes(status)

  const ticketTitle = sector && sector.name ? `${sector.name} - ${title}` : title

  const { t } = useTranslation()
  const { activeCurrency } = useActiveCurrency()
  const { codeUrl } = useParams<RouteParams['Event']>()
  const eventCurrencyCode = getCodeFromSymbol(currency as CurrencySymbol)

  const convertedPrice = convertAndFormat(priceNumber, eventCurrencyCode, activeCurrency)

  const execCallback = () => {
    if (eventDisabled) return
    callback(id, orderCodeDescription)
  }

  return (
    <Grid>
      <GridColumn
        gridColumnEnd={17}
        className={classNames(css.eventTicketMobile_container, { [css.disabled]: eventDisabled })}
      >
        <div className={css.eventTicketMobile_detailsContainer} onClick={execCallback}>
          <div className={classNames(css.eventTicketMobile_detailsLabels, { [css.grey]: eventDisabled })}>
            <h3 className={css.title}>{ticketTitle}</h3>
            <div className={css.details}>{details}</div>
          </div>
          <div className={classNames(css.eventTicketMobile_detailsPriceBox, { [css.grey]: eventDisabled })}>
            <div>
              {
                <h3 className={classNames(css.price, { [css.hide]: status === 'comingSoon' })}>{`${convertDotsToCommas(
                  convertedPrice
                )}`}</h3>
              }
              {status !== 'comingSoon' && <EventFeesButton codeUrl={codeUrl as string} />}
            </div>
            <div className={`${css.status} ${css[status]}`}>{t(ticketInfoByStatus[status].label)}</div>
          </div>
        </div>

        <div className={css.eventTicketMobile_ImageRightContainer}>
          <Img src={ticketInfoByStatus[status].img} />
        </div>
      </GridColumn>
    </Grid>
  )
}

export default Mobile
