import css from './Desktop.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { ITicket } from 'meta/backendControlled/structure'
import { CurrencySymbol } from 'meta/currency'
import DataTestId from 'meta/dataTestId'
import { ICheckoutDetails } from 'meta/pages/checkout'
import { useAppDispatch } from 'store'
import { CheckoutActions } from 'store/pages/checkout'
import { convertAndFormat, getCodeFromSymbol } from 'helpers/currency/converter'
import { Dates } from 'utils/dates'

import EventFeesButton from 'components/BackendControlled/Component/common/EventFeesButton'
import Img from 'components/Media/Img'
import { useActiveCurrency } from 'hooks/useActiveCurrency'
import { useCheckoutCartTicket, useCheckoutDetails, useCheckoutEventCodeUrl } from 'hooks/useCheckout'

interface IProps {
  data: ITicket
  addTickets(): void
  removeTickets(): void
}

const Desktop: React.FC<IProps> = (props) => {
  const { data, addTickets, removeTickets } = props
  const { id, title, priceNumber, currency, status, sector, availableUntil, details } = data

  const ticketTitle = sector && sector.name ? `${sector.name} - ${title}` : title

  const { t } = useTranslation()
  const { activeCurrency } = useActiveCurrency()
  const { eventDetails } = useCheckoutDetails() as ICheckoutDetails
  const cartTicket = useCheckoutCartTicket(id)
  const dispatch = useAppDispatch()

  const codeUrlFromCheckout = useCheckoutEventCodeUrl()

  const codeUrl = eventDetails && eventDetails.codeUrl ? eventDetails.codeUrl : codeUrlFromCheckout

  const getAvailableUntil = () => {
    return Dates.format(new Date(availableUntil), t('dates:common:ticket'))
  }

  const eventCurrencyCode = getCodeFromSymbol(currency as CurrencySymbol)

  const convertedPrice = convertAndFormat(priceNumber, eventCurrencyCode, activeCurrency)

  return (
    <div className={css.checkout_ticketselection_automatic_ticket_desktop_container}>
      <div className={`${css.ticket_container} ${status === 'active' ? css.green : css.yellow}`}>
        <div className={css.ticket_placeholder}>
          <Img src={'/tickets/ticket_logo_placeholder.svg'} />
        </div>

        <div className={css.ticket_details}>
          <h3 className={css.title}>{ticketTitle}</h3>

          {details && <div className={css.details}>{details}</div>}

          {availableUntil && (
            <div className={css.availableUntil}>{`${t(
              'checkout:purchase_available_until'
            )} ${getAvailableUntil()}`}</div>
          )}
        </div>

        <div className={css.ticket_price_box}>
          <div className={css.price_title}>{t('checkout:price_title')}</div>
          <h3 className={css.price}>{`${convertedPrice}`}</h3>
          {codeUrl && <EventFeesButton codeUrl={codeUrl} />}
          {/*<div className={css.presale}>{`+ ${convertedPresale} ${t('checkout:presale')}`}</div>*/}
        </div>

        <div className={css.ticket_select_background}>
          <Img src={'/tickets/ticket_quantity_selection_balloon_shorter.svg'} />
        </div>

        <div className={css.ticket_select_box}>
          <div
            className={css.minus}
            onClick={() => {
              removeTickets()
              dispatch(CheckoutActions.removeFromCart({ id }))
            }}
            data-testid="checkout-ticket-remove"
          >
            <Img src={'/tickets/ticket_select_minus.svg'} />
          </div>

          <h2 className={css.quantity}>{cartTicket ? cartTicket.quantity : 0}</h2>

          <div className={css.plus} onClick={addTickets} data-testid={DataTestId.checkout.btnTicketAdd}>
            <Img src={'/tickets/ticket_select_plus.svg'} />
          </div>
        </div>
      </div>

      <div className={css.ticket_tip_container}>
        {status === 'active' && <Img src={'/tickets/ticket_tip_green.svg'} />}
        {['exhaust', 'soldOut', 'paused'].includes(status) && <Img src={'/tickets/ticket_tip_yellow.svg'} />}
      </div>
    </div>
  )
}

export default Desktop
