import { CurrencySymbol } from 'meta/currency'

import { ComponentType, IComponent } from './component'

export enum TicketStatus {
  active = 'active',
  comingSoon = 'comingSoon',
  exhaust = 'exhaust',
  paused = 'paused',
  soldOut = 'soldOut',
}

interface TicketSector {
  name: string
}

export interface ITicket extends IComponent<ComponentType.ticket> {
  availableUntil: string
  currency?: CurrencySymbol
  details: string
  id: number
  mapSectorUuid?: string
  orderCodeDescription: string
  presale: string
  presaleNumber: number
  price: string
  priceNumber: number
  show?: boolean
  sector: TicketSector
  status: TicketStatus
  title: string
  typeTicketDescription?: string
}
