import { CurrencyCode } from 'meta/currency'
import { getSymbolFromCode } from 'helpers/currency/converter'

import { ComponentType, ITicket, TicketStatus } from '../../structure'
import { ITicketBackend } from '../component/ticket'
import ComponentAdapter from './componentAdapter'

export default class TicketAdapter extends ComponentAdapter<ITicket, ITicketBackend> {
  convert(): ITicket {
    const {
      endSellDatetime_tz,
      id,
      mapSectorUuid,
      notes,
      orderCodeDescription,
      presale,
      price,
      sector,
      stato,
      typeTicketDescription,
      webDetails,
    } = this.componentBackend

    const currency = getSymbolFromCode(presale.currency as CurrencyCode)

    return {
      componentType: ComponentType.ticket,
      availableUntil: endSellDatetime_tz,
      details: webDetails,
      id,
      mapSectorUuid,
      currency: currency,
      orderCodeDescription,
      presale: `${currency} ${Number(presale.amount) / 100}`,
      presaleNumber: Number(presale.amount) / 100,
      price: `${currency} ${Number(price.amount) / 100}`,
      priceNumber: Number(price.amount) / 100,
      sector,
      // @ts-ignore
      status: TicketStatus[stato],
      title: notes,
      typeTicketDescription: typeTicketDescription || '',
    }
  }
}
