import css from './Details.module.scss'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import { useGet } from 'api'
import classNames from 'classnames'

import { CurrencyCode, CurrencySymbol } from 'meta/currency'
import {
  IWalletUserTicket,
  IWalletUserTicketDetails,
  IWalletUserTicketPersonalData,
  IWalletUserTicketPrice,
} from 'meta/pages/wallet'
import { ApiEndpoint } from 'controller/endpoint'
import { convertAndFormat, getCodeFromSymbol } from 'helpers/currency/converter'
import { TICKETSMS_COLORS_MEDIUMGREY, TICKETSMS_COLORS_WHITE } from 'utils/constants/colors'
import { Dates } from 'utils/dates'

import { useActiveCurrency } from 'hooks/useActiveCurrency'
import { useAuthToken } from 'hooks/useAuth'
import { useCurrentLanguage } from 'hooks/useCurrentLanguage'
import { useOnUpdate } from 'hooks/useOnUpdate'

type Props = {
  show: boolean
  userTicket: IWalletUserTicket
}

const TicketCardDetails: React.FC<Props> = (props) => {
  const { show, userTicket } = props

  const { t } = useTranslation()

  const locale = useCurrentLanguage()
  const authToken = useAuthToken()
  const { response, fetch } = useGet()

  // eslint-disable-next-line
  // @ts-expect-error
  const [ticketDetails, setTicketDetails] = useState<IWalletUserTicketDetails>(null)

  const { activeCurrency } = useActiveCurrency()

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const [personalData, setPersonalData] = useState<IWalletUserTicketPersonalData>(null)

  const formatPrice = (
    object: IWalletUserTicketDetails,
    property: string,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    locale: string,
    activeCurrency: CurrencyCode = CurrencyCode.NONE
  ): string => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const { amount, symbol, formatted } = object[property] as IWalletUserTicketPrice

    const eventCurrencyCode = getCodeFromSymbol(symbol as CurrencySymbol)

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const convertedPrice = ticketDetails['zki']
      ? formatted
      : convertAndFormat(Number(amount) / 100, eventCurrencyCode, activeCurrency)

    return convertedPrice
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const formatSeasonTicket = (_object: boolean): string => {
    return ''
  }

  const formatDate =
    (dateFormat: string) =>
    (object: IWalletUserTicketDetails, property: string): string => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      return Dates.format(new Date(object[property]), dateFormat)
    }

  const fields: Array<{
    key: string
    dateFormat?: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    parser?: (object: any, property: string, locale: string, currency: CurrencyCode) => string
  }> = [
    {
      key: 'eventName',
    },
    {
      key: 'dateEvent',
      dateFormat: 'dates:wallet:dateEvent',
    },
    {
      key: 'locationName',
    },
    {
      key: 'openDateEvent',
      dateFormat: 'dates:wallet:dateOpening',
    },
    {
      key: 'buyerName',
    },
    {
      key: 'phone',
    },
    {
      key: 'organizerCompanyName',
    },
    {
      key: 'orderCodeDescription',
    },
    {
      key: 'ticketDescription',
    },
    {
      key: 'seasonCode',
    },
    {
      key: 'isSeasonTicket',
      parser: formatSeasonTicket,
    },
    {
      key: 'seasonTicketCode',
    },
    {
      key: 'seasonProgressive',
    },
    {
      key: 'numberOfEntrances',
    },
    {
      key: 'endDate_tz',
      dateFormat: 'dates:wallet:dateEvent',
    },
    {
      key: 'emissionDate',
      dateFormat: 'dates:wallet:dateEmission',
    },
    {
      key: 'emissionSystem',
    },
    {
      key: 'fiscalSeal',
    },
    {
      key: 'fiscalProgressive',
    },
    {
      key: 'activationCard',
    },
    {
      key: 'zki',
    },
    {
      key: 'jir',
    },
    {
      key: 'racun',
    },
    {
      key: 'workerOib',
    },
    {
      key: 'pvCode',
    },
    {
      key: 'price',
      parser: formatPrice,
    },
    {
      key: 'presale',
      parser: formatPrice,
    },
    {
      key: 'totalPrice',
      parser: formatPrice,
    },
    {
      key: 'statusLabel',
    },
  ]

  useEffect(() => {
    if (show && !ticketDetails) fetch({ authToken, urlResource: ApiEndpoint.users.walletTicket(userTicket.id) })
  }, [response, show])

  useOnUpdate(() => {
    response && setTicketDetails(response.data)
  }, [response])

  useOnUpdate(() => {
    if (response && response.data.personalData) {
      const { gender, dateOfBirth, birthplaceCityName, birthplaceCountry } = response.data.personalData

      const personalDataObj = {
        gender: String(t(`wallet:${gender}`)),
        birthDate: Dates.format(new Date(dateOfBirth), String(t('dates:wallet:dateEvent'))),
        birthplaceCountry: birthplaceCountry.name,
      }

      if (birthplaceCityName) {
        // eslint-disable-next-line
        // @ts-expect-error
        personalDataObj['birthplaceCity'] = birthplaceCityName.name
      }

      setPersonalData(personalDataObj)
    } else {
      // eslint-disable-next-line
      // @ts-expect-error
      setPersonalData(null)
    }
  }, [response])

  return (
    <>
      {!response && (
        <div className={classNames(css.wrapper__skeleton)}>
          <div className={classNames(css.details__skeleton, css.cardDetails__skeleton__secondItem)}>
            <Skeleton
              baseColor={TICKETSMS_COLORS_WHITE}
              borderRadius={4}
              height={'100%'}
              highlightColor={TICKETSMS_COLORS_MEDIUMGREY}
            />
          </div>
          {[0, 1, 2, 3, 4].map((item) => (
            <div key={item}>
              <div
                className={classNames(
                  css.details__skeleton,
                  css.details__width50__skeleton,
                  css.cardDetails__skeleton__secondItem
                )}
              >
                <Skeleton
                  baseColor={TICKETSMS_COLORS_WHITE}
                  borderRadius={4}
                  height={'100%'}
                  highlightColor={TICKETSMS_COLORS_MEDIUMGREY}
                />
              </div>
              <div
                className={classNames(
                  css.details__skeleton,
                  css.details__width60__skeleton,
                  css.cardDetails__skeleton__secondItem
                )}
              >
                <Skeleton
                  baseColor={TICKETSMS_COLORS_WHITE}
                  borderRadius={4}
                  height={'100%'}
                  highlightColor={TICKETSMS_COLORS_MEDIUMGREY}
                />
              </div>
              <div
                className={classNames(
                  css.details__skeleton,
                  css.details__width40__skeleton,
                  css.cardDetails__skeleton__secondItem
                )}
              >
                <Skeleton
                  baseColor={TICKETSMS_COLORS_WHITE}
                  borderRadius={4}
                  height={'100%'}
                  highlightColor={TICKETSMS_COLORS_MEDIUMGREY}
                />
              </div>
            </div>
          ))}
        </div>
      )}

      <div
        className={classNames(
          css.cardDetails,
          { [css.personalData]: personalData },
          { [css.isSeasonTicket]: ticketDetails && Boolean(ticketDetails['isSeasonTicket']) }
        )}
      >
        {ticketDetails &&
          fields.map((field) => {
            // eslint-disable-next-line
            // @ts-expect-error
            if (!ticketDetails[field.key] && field.key !== 'ticketDescription') {
              return
            }

            if (ticketDetails['isSeasonTicket'] && ['dateEvent', 'openDateEvent'].includes(field.key)) {
              return
            }

            if (field.key === 'ticketDescription') {
              return (
                <div key={field.key}>
                  <div className={css.cardDetails__key}>{`${t(`wallet:${field.key}`)}:`}</div>
                  <div className={css.cardDetails__value}>{String(ticketDetails['typeTicketDescription']).trim()}</div>
                </div>
              )
            }

            return (
              <div key={field.key}>
                <div className={css.cardDetails__key}>
                  {`${t(`wallet:${field.key == 'eventName' && ticketDetails['eventTickets'] ? 'seasonName' : field.key}`)}${field.key !== 'isSeasonTicket' ? ':' : ''}`}
                </div>
                <div className={css.cardDetails__value}>
                  {field.dateFormat
                    ? formatDate(String(t(field.dateFormat)))(ticketDetails, field.key)
                    : field.parser
                      ? field.parser(ticketDetails, field.key, locale, activeCurrency)
                      : // eslint-disable-next-line
                        // @ts-expect-error
                        String(ticketDetails[field.key]).trim()}
                </div>
              </div>
            )
          })}

        {ticketDetails && ticketDetails['eventTickets'] && (
          <div className={css.eventTickets}>
            {ticketDetails['eventTickets']
              .sort(function (a, b) {
                return new Date(a.event.datetime_tz).getTime() - new Date(b.event.datetime_tz).getTime()
              })
              .map((ticket, key) => {
                return (
                  <div className={css.eventData} key={key}>
                    <div>{`${ticket.event.name}`}</div>
                    <div>{`${Dates.format(new Date(ticket.event.datetime_tz), String(t('dates:wallet:dateTickets')))}`}</div>
                    <div>{`${String(t(`wallet:status_${ticket.status}`))}`}</div>
                  </div>
                )
              })}
          </div>
        )}

        {personalData &&
          Object.keys(personalData).map((name) => (
            <div key={name}>
              <div className={css.cardDetails__key}>{`${t(`wallet:${name}`)}:`}</div>
              {/* eslint-disable-next-line */}
              {/* @ts-expect-error */}
              <div className={css.cardDetails__value}>{personalData[name]}</div>
            </div>
          ))}

        {/* eslint-disable-next-line */}
        {/* @ts-expect-error */}
        {ticketDetails && ticketDetails['invoiceUrl'] && (
          <div
            className={css.printInvoice}
            onClick={() => {
              // eslint-disable-next-line
              // @ts-expect-error
              window.location.href = ticketDetails['invoiceUrl']
            }}
          >
            Print Invoice
          </div>
        )}
      </div>
    </>
  )
}

export default TicketCardDetails
